
@import './uikit//utilities/variables/variables';
@import './uikit/mixins/all';
@import './uikit//base/reset/reset';

.#{$travlr-ui} {
  & [class*="t-col-"] {
    float: left;
    padding-left: ($grid-gutter-width);
    padding-right: ($grid-gutter-width);
    width: 100%;
  }

  @include loopColumn(xs);

  @include media-up(sm) {
    @include loopColumn(sm);
  }

  @include media-up(md) {
    @include loopColumn(md);
  }

  @include media-up(lg) {
    @include loopColumn(lg);
  }

  @include media-up(xl) {
    @include loopColumn(xl);
  }
  .#{$travlr-prefix} {
    &row {
      margin-left: -($grid-gutter-width);
      margin-right: -($grid-gutter-width);

      @include clearfix();
    }

    &container {
      margin-left: auto;
      margin-right: auto;
      padding-left: ($grid-gutter-width * 2);
      padding-right: ($grid-gutter-width * 2);
      width: 100%;

      @include media-up(sm) {
        padding-left: ($grid-gutter-width * 3);
        padding-right: ($grid-gutter-width * 3);
      }

      @include media-up(md) {
        padding-left: ($grid-gutter-width * 4);
        padding-right: ($grid-gutter-width * 4);
      }

      @include media-up(xl) {
        padding-left: ($grid-gutter-width);
        padding-right: ($grid-gutter-width);
        max-width: $container-width;
      }
    }
  }
}
